import { useState, useEffect } from 'react';

export function useWindowHeight() {
  const isClient = typeof window === 'object';

  function getHeight() {
		return isClient ? window.innerHeight : undefined
	}

	const [windowHeight, setWindowHeight] = useState(getHeight);

	useEffect(() => {
		if (!isClient) {
			return false;
		}

		function handleResize() {
			setWindowHeight(getHeight());
		}

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);

	}, []);

	console.log('window Height is: ' + windowHeight)
	return windowHeight;
}