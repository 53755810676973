import React, { useEffect, useState, useRef } from "react";
import { Tabs, Upload } from 'antd';
import { Link, navigate } from 'gatsby'
import { Button } from "src/sws-ui"
import profileImage from "src/images/icons/profileImage.png";
import editProfile1 from "src/images/icons/editprofile.png";
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../state/action/profile'
import NoProfile from "src/images/icons/noProfile.png"
import classNames from "classnames";
import crossIcon from "src/images/icons/cross.png";
import { Formik } from 'formik';
import { Modal } from "react-responsive-modal"
import Swal from 'sweetalert2'
import { passwordchange, editPersonal, logout } from "../../state/action/login"
import { editAddress, editProfile, changePhoneno } from "../../state/action/profile";
import { nationalityAll, titleAll, getGender, FrequencyOfTransfer, Occupation, allSalary, ConvenientTimeToContact } from "../../state/action/currency";
import Resizer from 'react-image-file-resizer';
import translate from 'src/helpers/language';
import "react-responsive-modal/styles.css";
import {
  mobileOtp,
  submitEditinfo,
} from "../../state/action/login"


const Profile = () => {

  var globalvariable = {}
  const opt1 = useRef()
  const opt2 = useRef()
  const opt3 = useRef()
  const opt4 = useRef()
  const opt5 = useRef()
  const opt6 = useRef()
  const getProfileStatus = useSelector(state => state.profile)
  const englishCheck = /^[A-Za-z0-9@%+,><|'"!#$\-*/\()_.~ ]*$/
  const dispatch = useDispatch()
  const allProfiles = useSelector(state => state.currency)
  const allState = useSelector((state) => state);
  const { profile: { profile } } = allState;
  const [phoneNumber, setPhoneNumber] = useState('');
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!!localStorage.getItem("smartway_auth")) {
        dispatch(getProfile())
      } else {
        navigate(window.location.href.includes("/th") ? "/th/login" : "/en/login")
      }
    }
    setPhoneNumber(getProfileStatus?.profile?.PhoneNo)
  }, [])


  const [PhoneModal, showPhoneModal] = useState(false);



  const [imageForProfile, setProfileImage] = useState('')
  useEffect(() => {

    setProfileImage(profile?.ProfileHyperLink)
  }, [profile])

  // const fileChangedHandler = (file) => new Promise(resolve => {
  //   Resizer.imageFileResizer(file, 900, 900, 'JPEG', 100, 0,
  //   uri => {
  //     resolve(uri);
  //   },
  //   'base64'
  //   );
  //   });

	const handleLogout = () => {
		console.log('logging out')
		dispatch(logout())
	}


  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }



  const handleUpload = async (info) => {

    if (
      info.file.type.includes('/jpeg') ||
      info.file.type.includes('/jpg') ||
      info.file.type.includes('/png')
    ) {

      if (info.size >= 3000000) {
        Swal.fire('File size should be less then 3 MB.')
        return;
      }




      // Swal.fire('Compressing image...')
      // const imageUrl =  await fileChangedHandler(info.file);
      // dispatch(editProfile({...profile,ProfileBase64:imageUrl.split('base64,')[1]}));

      if (info.file) {
        getBase64(info.file.originFileObj, imageUrl => {
          setProfileImage(imageUrl);
          dispatch({
            type: 'GET_IMAGE',
            payload: imageUrl
          })

          dispatch(editProfile({ ...profile, ProfileBase64: imageUrl.split('base64,')[1] }, true));
        }
        );
      }
      //Swal.close()


    } else {
      Swal.fire('Invalid file')
      return
    }

  }

  const { TabPane } = Tabs;

  const pictureClasses = classNames("picture-holder", (imageForProfile !== '') && "picture-holder-img")

  const modalClasses = classNames("register-transfer__modal--select-account");
  useEffect(() => {
    dispatch(getGender());
    dispatch(Occupation());
    dispatch(allSalary());
    dispatch(FrequencyOfTransfer());
    dispatch(ConvenientTimeToContact());
    dispatch(titleAll());
    dispatch(nationalityAll());
  }, [])

  const getProfiledata = useSelector(state => state.profile?.profile)
  const [forgotPasswordModal, showForgotPasswordModal] = useState(false);
  const [passwordResetSuccessModal, showPasswordResetSuccessModal] = useState(false);
  const [addressModal, showAddressModal] = useState(false);

  const [otpConfirm, setOtpConfirm] = useState(false)
  const [setFirstOtpConfirm, firstOtpConfirm] = useState(false)
  const [openModel, setOpenModel] = useState(false)

  const [initialValues, setInitialValues] = useState({
    username: getProfileStatus?.profile?.Username,
    password: "",
    email: getProfileStatus?.profile?.Email,
    mobile: getProfileStatus?.profile?.PhoneNo,
    title: getProfileStatus?.profile?.TitleId,
    firstName: getProfileStatus?.profile?.FirstName,
    middleName: getProfileStatus?.profile?.MiddleName,
    lastName: getProfileStatus?.profile?.LastName,
    dob: getProfileStatus?.profile?.BirthDateISO,
    nationality: getProfileStatus?.profile?.NationalityId,
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  })

  const [phoneInitialValues, setPhoneInitialValues] = useState({
    mobile: getProfileStatus?.profile?.PhoneNo
  })

  const handleForgotPasswordModal = () => {
    showForgotPasswordModal(!forgotPasswordModal);
  };

  const handlePasswordResetSuccessModal = () => {
    showPasswordResetSuccessModal(!passwordResetSuccessModal);
    showForgotPasswordModal(!forgotPasswordModal);
  };

  const handleCloseResetPasswordModal = () => {
    showPasswordResetSuccessModal(!passwordResetSuccessModal);
  };

  const handlePhoneModal = () => {
    dispatch(getProfile());
    setPhoneInitialValues({
      mobile: getProfileStatus?.profile?.PhoneNo
    })
    showPhoneModal(!PhoneModal);
  }

  return (
    <>

      <section className="profile-section">



        {forgotPasswordModal && (
          <div className="transfer__modal">
            <div className={modalClasses}>
              <div className="transfer__modal--cross" onClick={handleForgotPasswordModal}>
                <img src={crossIcon} className="transfer__modal--cross-img" />
              </div>
              <h2>{translate('Reset Password', 'รีเซ็ตรหัสผ่าน')}</h2>
              <p>
                {translate('Reset your password. Passwords must be at least 6 characters long.', 'รีเซ็ตรหัสผ่านของคุณ. รหัสผ่านต้องมีความยาวอย่างน้อย 6 อักขระ')}
              </p>
              <Formik
                initialValues={{ confirm: '', password1: '', password2: '' }}
                validate={values => {
                  const errors = {};
                  if (!values.password1) {
                    errors.password1 = 'Required';
                  }
                  if (!values.password2) {
                    errors.password2 = 'Required';
                  }
                  else if (values.password1 !== values.password2) {
                    errors.password2 = 'password not matched';
                  }
                  if (!values.confirm) {
                    errors.confirm = 'Required';
                  }
                  return errors;
                }}
                onSubmit={async (values) => {
                  Swal.showLoading()
                  const response = await dispatch(passwordchange(values.confirm, values.password2))
                  Swal.close()
                  if (response.data.StatusCode === "0") {
                    handlePasswordResetSuccessModal()

                  }
                  else if (response.data.StatusCode === "01") {
                    Swal.fire({
                      title: response.data.Message,
                      text: "Please try to login again.",
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Login'
                    }).then((result) => {
                      if (result.isConfirmed) {
                        localStorage.removeItem("smartway_auth")
                        window.location.href = typeof window !== 'undefined' && window.location.href.includes('/th') ? '/th/login' : '/en/login';
                      }
                    })
                  }
                  else {

                    Swal.fire(response.data.Message)
                  }



                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>


                    <div className="currency-group" style={{'marginBottom': '30px'}}>
                      <div className="currency-input" style={{'marginBottom': '0'}}>
                        <input
                          type="password"

                          placeholder="Enter Current Password"
                          name="confirm"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.confirm}
                        />
                      </div>
                      <div className="error-form">
                        {errors.confirm && touched.confirm && errors.confirm}
                      </div>
                    </div>
                    <div className="currency-group" style={{'marginBottom': '30px'}}>
                      <div className="currency-input" style={{'marginBottom': '0'}}>
                        <input
                          type="password"

                          placeholder="Enter New Password"
                          name="password1"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password1}
                        />
                      </div>
                      <div className="error-form">
                        {errors.password1 && touched.password1 && errors.password1}
                      </div>
                    </div>
                    <div className="currency-group" style={{'marginBottom': '30px'}}>
                      <div className="currency-input" style={{'marginBottom': '0'}}>
                        <input
                          type="password"

                          placeholder="Re-enter New Password"
                          name="password2"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.password2}
                        />
                      </div>
                      <div className="error-form">
                        {errors.password2 && touched.password2 && errors.password2}
                      </div>
                    </div>
                    <div>
                      <button
                        type="submit"
                        className="btn bold btn--yellow"

                      >
                        {translate('Reset Password', 'รีเซ็ตรหัสผ่าน')}
                      </button>
                    </div>

                  </form>
                )}
              </Formik>

            </div>
          </div>
        )}

        {passwordResetSuccessModal && (
          <div className="transfer__modal">
            <div className={modalClasses}>
              <div className="transfer__modal--cross" onClick={handleCloseResetPasswordModal}>
                <img src={crossIcon} className="transfer__modal--cross-img" />
              </div>
              <h2>{translate('Password Reset Successful', 'รีเซ็ตรหัสผ่านสำเร็จ')}</h2>
              <p>
                {translate('Your password has been successfully reset. Please log in again to continue.', 'รีเซ็ตรหัสผ่านของคุณสำเร็จแล้ว กรุณาเข้าสู่ระบบอีกครั้งเพื่อดำเนินการต่อ')}
              </p>

              <div className="btn bold btn--yellow" onClick={() => {
                    localStorage.removeItem("smartway_auth")
                    handleLogout();
                    navigate(typeof window !== "undefined" && window.location.href.includes("/th")
                    ? "/th/login"
                    : "/en/login")
                  }}>
                <a

                >
                  {translate('Login', 'เข้าสู่ระบบ')}
                </a>
              </div>
            </div>
          </div>
        )}

        {PhoneModal && (
          <div className="transfer__modal">
            <div className={modalClasses}>
              <div className="transfer__modal--cross" onClick={handlePhoneModal}>
                <img src={crossIcon} className="transfer__modal--cross-img" />
              </div>
              <h2>{translate('Change Phone Number', 'อัปเดตหมายเลขโทรศัพท์ของคุณ')}</h2>
              <p>Please enter your new phone number and continue to verify</p>

              <Formik
              enableReinitialize={true}
              initialValues={phoneInitialValues}
              validate={values => {
                const errors = {}

                // if (!values.email) {
                //   errors.email = "Required";
                // } else if (
                //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                // ) {
                //   errors.email = "Invalid email address";
                // }

                // if (!values.username) {
                //   errors.username = "Required";
                // }
                // if (!values.password) {
                //   errors.password = "Required";
                // }
                // else if (values.password.length <= 5) {
                //   errors.password = "Password must be 6 chracter long";
                // }

                if (!values.mobile) {
                  errors.mobile = "Required"
                } else if (String(values.mobile).length !== 9) {
                  errors.mobile = "Number should be like +61XXXXXXXXX"
                } else if (!englishCheck.test(values.mobile)) {
                  errors.mobile =
                    "Please fill in English"
                }

                return errors
              }}
              onSubmit={async (values, { setSubmitting }) => {
                // setError("");
                // handleModalNormal();
                // const hasMobilePrefix = values.mobile.substring(0,3)
                // if (hasMobilePrefix != '+61') {
                //   const mobile = '+61' + values.mobile
                //   values.mobile = mobile
                //   console.log(`new mobile is ${values.mobile}`)
                // }
                console.log(values.email)
                // Swal.showLoading()
                console.log('OTP CONFIRM: ')
                console.log(otpConfirm)
                if (
                  ((getProfileStatus.profile?.IsComfirmPhoneNo == false) && (otpConfirm == false))
                  || (getProfileStatus?.profile?.PhoneNo != values.mobile)
                ) {
                  const checkDuplicatePhone = await dispatch(
                    changePhoneno(values.mobile)
                  )
                  console.log(checkDuplicatePhone)
                  if (checkDuplicatePhone.data?.StatusCode === "08") {
                    Swal.close()
                    setOpenModel(true)
                  }
                  // await dispatch(
                  //   mobileOtp(
                  //     parseInt(localStorage.getItem("smartway_user_info")),
                  //     values.mobile
                  //   )
                  // )

                  // setOpenModel(true)
                } else {
                  const accountInfoData = await submitEditinfo(values)

                  if (accountInfoData.data.StatusCode === "0") {
                    setInitialValues(values)
                    // getAddress()
                  } else {
                    Swal.fire(accountInfoData.data.Message)
                  }
                }

                globalvariable = values
              }}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div></div>

                  <div className="currency-group currency-group--transfer">
                    <div className="currency-input relative-currency-prefix mobile-input-class">
                      <input
                        placeholder="Mobile No. (+614XXXXXXXX)"
                        type="number"
                        name="mobile"
                        onChange={(e) => {
                          handleChange(e)
                          console.log(e.value)
                          console.log(initialValues.mobile)
                            // if(e.value != initialValues.mobile) {
                            //   setFirstOtpConfirm(false)
                            // }
                          }
                        }
                        onBlur={handleBlur}
                        value={values.mobile}
                      />
                      <div className="prefix-mobile">+61</div>
                    </div>
                    <div className="error-form">
                      {errors.mobile && touched.mobile && errors.mobile}
                    </div>
                  </div>
                  <button
              type="submit"
              className="btn bold btn--yellow"
              // onClick={handlePhoneModal}
            >
              {translate('Continue to Verify', 'ส่งและดำเนินการต่อ')}
            </button>

                  <Modal open={openModel} onClose={() => setOpenModel(false)}>

                  <h2>Phone Number Verification</h2>
                  <p>Enter 6 digits OTP sent to +61{values.mobile}</p>
                  <div className="otp-inputs">
                    <input
                      type="text"
                      maxlength="1"
                      style={{width: '40px', fontSize: '24px'}}
                      name="otp1"
                      onChange={e => {
                        handleChange(e)
                      }}
                      onKeyUp={({ key }) => {
                        if (key === "Backspace") {
                        } else {
                          opt2.current.focus()
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.otp1}
                      ref={opt1}
                    />
                    <input
                      ref={opt2}
                      type="text"
                      maxlength="1"
                      style={{width: '40px', fontSize: '24px'}}
                      name="otp2"
                      onChange={e => {
                        handleChange(e)
                      }}
                      onKeyUp={({ key }) => {
                        if (key === "Backspace" || key === "ArrowLeft") {
                          opt1.current.focus()
                          opt1.current.select()
                        } else {
                          opt3.current.focus()
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.otp2}
                    />
                    <input
                      ref={opt3}
                      type="text"
                      maxlength="1"
                      style={{width: '40px', fontSize: '24px'}}
                      name="otp3"
                      onChange={e => {
                        handleChange(e)
                      }}
                      onKeyUp={({ key }) => {
                        if (key === "Backspace" || key === "ArrowLeft") {
                          opt2.current.focus()
                          opt2.current.select()
                        } else {
                          opt4.current.focus()
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.otp3}
                    />
                    <input
                      ref={opt4}
                      type="text"
                      maxlength="1"
                      style={{width: '40px', fontSize: '24px'}}
                      name="otp4"
                      onChange={e => {
                        handleChange(e)
                      }}
                      onKeyUp={({ key }) => {
                        if (key === "Backspace" || key === "ArrowLeft") {
                          opt3.current.focus()
                          opt3.current.select()
                        } else {
                          opt5.current.focus()
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.otp4}
                    />
                    <input
                      ref={opt5}
                      type="text"
                      maxlength="1"
                      style={{width: '40px', fontSize: '24px'}}
                      name="otp5"
                      onChange={e => {
                        handleChange(e)
                      }}
                      onKeyUp={({ key }) => {
                        if (key === "Backspace" || key === "ArrowLeft") {
                          opt4.current.focus()
                          opt4.current.select()
                        } else {
                          opt6.current.focus()
                        }
                      }}
                      onBlur={handleBlur}
                      value={values.otp5}
                    />
                    <input
                      ref={opt6}
                      type="text"
                      maxlength="1"
                      style={{width: '40px', fontSize: '24px'}}
                      name="otp6"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otp6}
                      onKeyUp={({ key }) => {
                        if (key === "Backspace" || key === "ArrowLeft") {
                          opt5.current.focus()
                          opt5.current.select()
                        }
                      }}
                    />
                  </div>
                  <div
                    className="btn-send-otp"
                    style={{cursor: 'pointer'}}
                    onClick={async () => {
                      await dispatch(
                        mobileOtp(
                          parseInt(
                            localStorage.getItem("smartway_user_info")
                          ),
                          values.mobile
                        )
                      )
                      values.otp1 = ''
                      values.otp2 = ''
                      values.otp3 = ''
                      values.otp4 = ''
                      values.otp5 = ''
                      values.otp6 = ''
                      // setOpenModel(false)


                      Swal.fire({
                        title: "Resent OTP successfully.",
                        icon: "success",
                      })
                    }
                  }
                  >
                    Resend OTP
                  </div>
                  <div
                    className="btn-send-otp"
                    onClick={async () => {
                      Swal.showLoading()
                      const otpVerify = await dispatch(changePhoneno(values.mobile, values.otp1.toString() + values.otp2.toString() + values.otp3.toString() + values.otp4.toString() + values.otp5.toString() + values.otp6.toString()))

                      if (otpVerify?.data?.StatusCode === "0") {
                        setOpenModel(false)
                        showPhoneModal(false)
                        dispatch(getProfile())
                        Swal.close()
                        Swal.fire({
                          title: "Phone Number Changed",
                          confirmButtonColor: "#3085d6",
                          confirmButtonText: "Ok",
                        })
                        setOtpConfirm(true)
                        setInitialValues(values)
                        // getAddress()
                        // setFirstOtpConfirm(true)
                        values.otp1 = ''
                        values.otp2 = ''
                        values.otp3 = ''
                        values.otp4 = ''
                        values.otp5 = ''
                        values.otp6 = ''
                      } else {
                        // Swal.fire(otpVerify?.data?.Message)
                        // getAddress()
                      }
                    }}
                  >
                    Ok
                  </div>
                  </Modal>
                </form>

              )}
              </Formik>


            </div>
          </div>
        )}



        <div class="profile-section__tabs">

          <div class="profile-section__general">
            <div class="profile-picture">
              <div className={pictureClasses}>

                    <img src={imageForProfile || NoProfile} class="profile-picture__image" />

                <Upload onChange={handleUpload}>
                  <div></div>
                </Upload>
              </div>
              <p class="profile-name">{getProfiledata?.FirstName || ""}</p>
            </div>
            <div>
              <Tabs defaultActiveKey="1" animated={{ inkBar: true, tabPane: false }}>
                <TabPane tab={translate('Account', 'บัญชีผู้ใช้')} key="1">
                  <div class="profile-section__general--details">
                    <div class="profile-section__column">
                      <div className="profile-details--wrapper">
                        <h4>{translate('Username', 'ชื่อผู้ใช้')}</h4>
                        <p>{getProfiledata?.UserName}</p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Email', 'อีเมล์')}</h4>

                        <p>{getProfiledata?.Email}</p>
                      </div>
                    </div>
                    <div class="profile-section__column">
                      <div className="profile-details--wrapper">
                        <h4>{translate('Phone Number', 'หมายเลขโทรศัพท์')}</h4>
                        <p>{'+61' + getProfiledata?.PhoneNo}</p>
                        {(
                          getProfiledata?.MemberProfileStatusId === 1 ||
                          getProfiledata?.MemberProfileStatusId === 4 ||
                          getProfiledata?.MemberProfileStatusId === 5 ||
                          getProfiledata?.MemberProfileStatusId === 7 ||
                          getProfiledata?.MemberProfileStatusId === null
                        ) && (

                            <div className="edit__icon-container1" onClick={handlePhoneModal}>
                              <img src={editProfile1}></img>
                            </div>
                          )}
                      </div>
                    </div>
                      <p class="forgot-password" onClick={handleForgotPasswordModal}>Reset password</p>
                  </div>
                  {/* <div className="edit__icon-container" onClick={handleAccountModal}>
                  <img src={editProfile1}></img>
                </div> */}


                </TabPane>
                <TabPane tab={translate('Personal Details', 'ข้อมูลส่วนตัว')} key="2">
                  <div class="profile-section__general--details">
                    <div class="profile-section__column">
                      <div className="profile-details--wrapper">
                        <h4>{translate('Name', 'ชื่อ')}</h4>
                        <p>{getProfiledata?.FirstName || ""}</p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Date of Birth', 'วันเกิด')}</h4>
                        <p>{getProfiledata?.BirthDateText || ""}</p>
                      </div>
                    </div>
                    <div class="profile-section__column">
                      <div className="profile-details--wrapper">
                        <h4>{translate('Nationality', 'สัญชาติ')}</h4>
                        <p>Australian</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="edit__icon-container" onClick={handlePersonalDetailsModal}>
                  <img src={editProfile1}></img>
                </div> */}


                </TabPane>
                <TabPane tab={translate('Address', 'ที่อยู่')} key="3">
                  <div class="profile-section__general--details">
                    <div class="profile-section__column">
                      <div className="profile-details--wrapper">
                        <h4>{translate('Unit Number', 'เลขที่หน่วย')}</h4>
                        <p>{getProfiledata?.AusUnitNumber || ''}</p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Street Name', 'ชื่อถนน')}</h4>
                        <p>{getProfiledata?.AusStreetName || ''}</p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Suburb', 'ชานเมือง')}</h4>
                        <p>{getProfiledata?.AusSuburb || ''}</p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Postcode', 'รหัสไปรษณีย์')}</h4>
                        <p>{getProfiledata?.AusPostalCode || ''}</p>
                      </div>
                    </div>
                    <div class="profile-section__column">
                      <div className="profile-details--wrapper">
                        <h4>{translate('Street Number', 'เลขที่ถนน')}</h4>
                        <p>{getProfiledata?.AusStreetNumber || ''}</p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Street Type', 'ประเภทถนน')}</h4>
                        <p>{getProfiledata?.AusStreetType || ''}</p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('State', 'สถานะ')}</h4>
                        <p>{getProfiledata?.AusState || ''}</p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="edit__icon-container" onClick={handleAddressModal}>
                  <img src={editProfile1}></img>
                </div> */}
                </TabPane>
                <TabPane tab={translate('Other Details', 'รายละเอียดอื่น ๆ')} key="4">
                  <div class="profile-section__general--details">
                    <div class="profile-section__column">
                      <div className="profile-details--wrapper">
                        <h4>{translate('Gender', 'เพศ')}</h4>
                        <p>
                          {!!getProfiledata?.GenderId &&
                            allProfiles.gender?.map(id => {
                              return id.GenderId === getProfiledata?.GenderId && (
                                id.GenderName
                              )
                            })
                          }

                        </p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Occupation', 'อาชีพ')}</h4>
                        <p>
                          {!!getProfiledata?.OccupationId &&
                            allProfiles.occupation?.map(id => {
                              return id.OccupationId === getProfiledata?.OccupationId && (
                                id.OccupationName
                              )
                            })
                          }
                        </p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Salary', 'เงินเดือน')}</h4>
                        <p>
                          {!!getProfiledata?.SalaryId &&
                            allProfiles.salary?.map(id => {
                              return id.SalaryId === getProfiledata?.SalaryId && (
                                id.SalaryName
                              )
                            })
                          }
                        </p>
                      </div>
                    </div>
                    <div class="profile-section__column">
                      <div className="profile-details--wrapper">
                        <h4>{translate('Frequency of Transfer', 'ความถี่ในการโอน')}</h4>
                        <p>
                          {!!getProfiledata?.FrequencyOfTransferId &&
                            allProfiles?.frequencyTransfer?.map(id => {
                              return id.FrequencyOfTransferId === getProfiledata?.FrequencyOfTransferId && (
                                id.FrequencyOfTransferName
                              )
                            })
                          }
                        </p>
                      </div>
                      <div className="profile-details--wrapper">
                        <h4>{translate('Convenient time for contact', 'เวลาที่สะดวกในการติดต่อ')}</h4>
                        <p>

                          {!!getProfiledata?.ConvenientTimeToContactId &&
                            allProfiles.timeConnect?.map(id => {
                              return id.ConvenientTimeToContactId === getProfiledata?.ConvenientTimeToContactId && (
                                id.ConvenientTimeToContactName
                              )
                            })
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="edit__icon-container" onClick={handleOtherDetailsModal}>
                  <img src={editProfile1}></img>
                </div> */}
                </TabPane>


              </Tabs>
              {getProfiledata?.MemberStatusId === 1 &&

              <Link

              disabled={
                (
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 3) ||
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 6)

                ) ?
                true : false


              }
              to={
                (
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 1) ||
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 4) ||
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 5) ||
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === null)
                ) &&

                  typeof window !== 'undefined' && window.location.href.includes('/th') ? '/th/register2' : '/en/register2'


              }

              className={
                (
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 1) ||
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 4) ||
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 5) ||
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 2) ||
                  (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === null)
                ) ?
                "btn btn--yellow btn--incomplete-profile" : "btn btn--yellow btn--incomplete-profile btn--incomplete-profile-disabled"
              }

              >

                {
                  (
                    (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 1) ||
                    (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 4) ||
                    (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 5) ||
                    (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 2) ||
                    (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === null)

                  ) &&
                  'Complete registration'
                }{
                  (
                    (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 3) ||
                    (getProfiledata?.MemberStatusId === 1 && getProfiledata?.MemberProfileStatusId === 6)

                  ) &&
                  'Waiting for approval'


                }</Link>
              }


            </div>

          </div>.
       </div>
      </section>

    </>
  );
};

export default Profile;
